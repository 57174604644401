import axios from 'axios';
// Your OpenAI API Key
const apiKey = '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';

const headers = {
  'Authorization': `Bearer ${apiKey}`,
  'Content-Type': 'application/json',
  'OpenAI-Beta': 'assistants=v2'
}

const BASE_URL = 'https://api.openai.com/v1';

const createThreadAndRun = async (userMessage, assistantId) => {
  const URL = `${BASE_URL}/threads/runs`;

  const body = {
    assistant_id: assistantId,
    tool_choice: {
      "type": "file_search"
    },
    thread: {
      messages: [
        { role: "user", content: userMessage }
      ]
    }
  }

  try {
    const response = await axios.post(
      URL,
      body,
      { headers }
    );

    return {
      runId: response.data.id,
      threadId: response.data.thread_id
    }

  } catch (error) {
    console.error('Error sending message:', error.response?.data || error.message);
    throw new Error('Failed to send message');
  };
}

const getMessages = async (threadId) => {
  // No idea why this is required, otherwise it sees threatId as null
  console.log('Getting messages for thread:', threadId);

  if (!threadId) {
    console.error('Thread ID is required');
    throw new Error('Thread ID is required');
  }
  
  const URL = `${BASE_URL}/threads/${threadId}/messages`;

  try {
    const response = await axios.get(
      URL,
      { headers }
    );

    return response.data;
  } catch (error) {
    console.error('Error getting messages:', error.response?.data || error.message);
    throw new Error('Failed to get messages');
  };
}

const createRunWithMessage = async (threadId, assistantId, userMessage) => {
  const URL = `${BASE_URL}/threads/${threadId}/runs`;

  const body = {
    assistant_id: assistantId,
    additional_messages: [
      { role: "user", content: userMessage }
    ]
  }

  try {
    const response = await axios.post(
      URL,
      body,
      { headers }
    );

    return response.data.id;
  } catch (error) {
    console.error('Error sending message:', error.response?.data || error.message);
    throw new Error('Failed to send message');
  };
}


export {
  createThreadAndRun,
  getMessages,
  createRunWithMessage,
};