import React from 'react';

const Sidebar = ({ models, selectModel }) => {
  return (
    <div className="w-64 h-full bg-gray-800 text-white p-4">
      <h2 className="text-xl mb-4">Select Model</h2>
      <ul>
        {models.map((model, index) => (
          <li 
            key={index} 
            className="cursor-pointer hover:bg-gray-600 p-2 mb-2 rounded"
            onClick={() => selectModel(model)}
          >
            {model}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
