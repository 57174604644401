import React from "react";

const AssistantList = ({ assistants, selectedAssistant, onSelectAssistant }) => {
  return (
    <div className="w-64 bg-gray-100 p-4 border-r">
      <h2 className="text-lg font-bold mb-4">Knowledge</h2>
      <h3 className="text-md mb-4">Select a pre-trained model that cites the references. Say goodbye to PDFs.</h3>
      <ul>
        {assistants.map((assistant) => (
            <li
            key={assistant.id}
            className={`p-2 rounded-lg cursor-pointer ${
              !assistant.break
                ? selectedAssistant && selectedAssistant.id === assistant.id
                  ? "bg-blue-500 text-white"
                  : "hover:bg-gray-200"
                : "text-black-500 font-bold cursor-default disabled"
            }`}
            onClick={() => { if (!assistant.break) { onSelectAssistant(assistant) } else { return null }}}
          >
            {assistant.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AssistantList;
