import React, { useState } from "react";
import AssistantList from "../components/AssistantList";
import ChatBox from "../components/ChatBox";

const App = () => {
  const [selectedAssistant, setSelectedAssistant] = useState(null);

  const assistants = [
    { id: "break", name: "Aviation", assistantId: null, break: true },
    { id: "aviation", name: "Flight Duty Times", assistantId: "asst_1e7qnRNsOync4UYmgEMGsSZx" },
    { id: "poh-c150", name: "Cessna 150 POH", assistantId: "asst_7HcPTfqYGFffUXXCCUsN6u93"},
    { id: "break-3", name: "Ideas (not implemented)", assistantId: null, break: true },
    { id: "private", name: "Private Flying Part 91", assistantId: null},
    { id: "maintenance", name: "Maintenance Part 145", assistantId: null},
    { id: "poh-g58", name: "Beechcraft G58 POH", assistantId: null},
    { id: "atsb", name: "ATSB Safety Reports", assistantId: null},
    { id: "licensing", name: "Examiners Handbook & Licensing", assistantId: null},
    { id: "break-2", name: "Property", assistantId: null, break: true},
    { id: "basix", name: "BASIX Regulations", assistantId: null},
    { id: "cessnock-das", name: "Cessnock DAs", assistantId: null}
  ];

  return (
    <div className="flex h-screen">
      <AssistantList
        assistants={assistants}
        selectedAssistant={selectedAssistant}
        onSelectAssistant={setSelectedAssistant}
      />
      <div className="flex-1">
        {selectedAssistant ? (
          <ChatBox assistantId={selectedAssistant.assistantId} />
        ) : (
          <div className="flex items-center justify-center h-full text-gray-500">
            Select an assistant to get started
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
