import React from "react";

const Message = ({ type, text }) => {
  return (
    <div
      className={`p-2 my-2 rounded-lg ${
        type === "user" ? "bg-blue-100 self-end" : "bg-gray-200 self-start"
      }`}
    >
      {text}
    </div>
  );
};

export default Message;
