import React from 'react';

const ResultCard = ({ result }) => {
  return (
    <div className="p-4 mb-4 border rounded-lg bg-white">
      <h2 className="text-xl font-semibold mb-2">Result...</h2>
      <p className="text-gray-800">{result}</p>
    </div>
  );
};

export default ResultCard;
