import React, { useState } from "react";
import Message from "./Message.js";

import { createRunWithMessage, createThreadAndRun, getMessages } from "../services/assistantInteraction.js";

const ChatBox = ({ assistantId }) => {
  const [messages, setMessages] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const [threadExists, setThreadExists] = useState(false);

  const handleFirstSend = async () => {
    setThreadExists(true);

    if (!query) return;
    setLoading(true);

    const newMessages = [{ type: "user", text: query }];
    setMessages(newMessages);
    setQuery("");

    // Send message to assistant
    const response = await createThreadAndRun(query, assistantId);
    console.log(`Thread ID: ${response.threadId}, Run ID: ${response.runId}`);

    setThreadId(response.threadId);

    // delay 2 seconds
    await new Promise((resolve) => setTimeout(resolve, 4000));

    // // Get messages from assistant
    const assistantMessages = await getMessages(response.threadId);

    const updatedMessages = [{ ...newMessages[0], messageId: assistantMessages.data[assistantMessages.data.length - 1].id }]
    setMessages(updatedMessages);

    console.log(`Messages: ${JSON.stringify(assistantMessages)}`);

    assistantMessages.data.slice(0,-1).forEach((msg) => {
        console.log(`Message: ${msg.content[0].text.value}, Role: ${msg.role}`);
        updatedMessages.push({ 
            type: msg.role,
            text: removeSources(msg.content[0].text.value),
            messageId: msg.id
        });
    });

    setLoading(false);
  };

  const handleSubsequentSend = async () => {
    if (!query || !threadId) return;
    setLoading(true);

    const newMessages = [...messages, { type: "user", text: query }];
    setMessages(newMessages);
    setQuery("");

    await createRunWithMessage(threadId, assistantId, query);

    await new Promise((resolve) => setTimeout(resolve, 4000));

    const assistantMessages = await getMessages(threadId);

    console.log(assistantMessages)

    newMessages.push({
        type: assistantMessages.data[0].role,
        text: removeSources(assistantMessages.data[0].content[0].text.value),
        messageId: assistantMessages.data[0].id
    })

    setLoading(false);
  };

  const removeSources = (message) => {
    return message.replace(/【.*?】/g, "")
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 p-4 overflow-y-auto">
        {messages.map((msg, index) => (
          <Message key={index} type={msg.type} text={msg.text} />
        ))}
      </div>
      <div >
        { loading ? <div className="flex items-center justify-center h-full text-gray-500">Loading...</div> : null }
      </div>
      <div>{assistantId}</div>
      <div className="p-4 border-t flex items-center">
        <input
          type="text"
          className="flex-1 border p-2 rounded-lg"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Ask a question..."
        />
        <button
          className="ml-4 bg-blue-500 text-white p-2 rounded-lg"
          onClick={threadExists ? handleSubsequentSend : handleFirstSend}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatBox;
