import axios from 'axios';

const searchQuery = async (query, model) => {

  const URL = 'https://api.openai.com/v1/chat/completions';
  const API_KEY = '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'

  const custom_model = 'asst_1e7qnRNsOync4UYmgEMGsSZx';

  const params = {
    model: custom_model,
    messages: [
      { role: 'user', content: query }
    ]
  }
  
  const headers = {
      'Authorization': `Bearer ${API_KEY}`,
      'Content-Type': 'application/json'
  }

  try {
    const response = await axios.post(URL,
      params,
      { headers },
    );

    console.log(response.data)
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error during API call:', error);
    return null;
  }
};

export default searchQuery;
