import { useState } from 'react';
import Sidebar from '../components/Sidebar';
import SearchBar from '../components/SearchBar';
import ResultCard from '../components/ResultCard';
import searchQuery from '../api/searchAPI';

const App = () => {
  const [selectedModel, setSelectedModel] = useState(null);
  const [results, setResults] = useState([]);
  const models = ['Model 1', 'Model 2', 'Model 3']; // Example models

  const handleSearch = async (query) => {
    if (!selectedModel) {
      alert('Please select a model first.');
      return;
    }

    const result = await searchQuery(query, selectedModel);
    setResults(result);
  };

  return (
    <div className="flex">
      <Sidebar models={models} selectModel={setSelectedModel} />
      <div className="flex-1 p-6">
        {selectedModel && <h1 className="text-2xl mb-4">{selectedModel}</h1>}
        <SearchBar onSearch={handleSearch} />
        { results ? <ResultCard result={results} /> : <p>No results found</p> }
        {/* {results.length > 0 && results.map((res, index) => (
          <ResultCard key={index} result={res} />
        ))} */}
      </div>
    </div>
  );
};

export default App;
